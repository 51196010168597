<template>
  <div class="profile mt-4">
    <v-row class="text-center">
      <v-col cols="12">
        <v-avatar color="grey lighten-2" size="100">
          <v-img :src="imageSrc" max-width="100%" max-height="100%" />
        </v-avatar>
      </v-col>

      <v-col cols="12">
        <div class="font-weight-bold main--text text-heading-7 font-italic">
          {{ user.last_name + " " + user.first_name }}
        </div>
        <div class="font-weight-medium main--text lighten-2 text-heading-6">
          {{ user.furigana_last_name }} {{ user.furigana_first_name }}
        </div>
        <div class="text-right">
          <v-btn
            @click="$router.push({ name: 'edit_profile' })"
            rounded
            color="white"
          >
            編集
            <v-icon right size="15">
              mdi-pencil-outline
            </v-icon>
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12" class="text-left">
        <div
          class="grey lighten-3 text-heading-6 blue--text text--darken-4 font-weight-bold px-5 text-left pa-3"
        >
          基本情報
        </div>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>ID</v-list-item-subtitle>
            <v-list-item-title>{{ user.id || "-" }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>郵便番号</v-list-item-subtitle>
            <v-list-item-title>〒{{ user.postcode || "-" }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>住所</v-list-item-subtitle>
            <v-list-item-title>{{ (user.prefecture ? user.prefecture.name : '') + ' ' + (user.city ? user.city : '') + ' ' + user.address || "-" }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>生年月日</v-list-item-subtitle>
            <v-list-item-title>{{ user.birth_day || "-" }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>パスワード</v-list-item-subtitle>
            <v-list-item-title>*********</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div
          class="grey lighten-3 text-heading-6 blue--text text--darken-4 font-weight-bold px-5 text-left pa-3"
        >
          連絡先
        </div>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>メール</v-list-item-subtitle>
            <v-list-item-title>{{ user.email || "-" }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>電話番号</v-list-item-subtitle>
            <v-list-item-title>{{ user.phone || "-" }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>緊急連絡先</v-list-item-subtitle>
            <v-list-item-title>{{
              user.emergency_contact || "-"
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
export default {
  name: "ProfilePage",
  computed: {
    ...mapGetters(["user"]),
    imageSrc() {
      return this.user.profile_image_url || `${process.env.VUE_APP_ROOT_API}images/userblank.png`
    }
  },
  data() {
    return {
      accountDetails: [
        {
          name: "id",
          text: this.$t("member_id"),
          value: "CP001"
        },
        {
          name: "joined_date",
          text: this.$t("member_since"),
          value: dayjs().format("YYYY-MM-DD")
        },
        {
          name: "account_type",
          text: this.$t("account_type"),
          value: "Business"
        }
      ]
    };
  }
};
</script>

<style scoped>
.profile {
  max-width: 100% !important;
}
.account-details-table >>> td {
  border: unset !important;
}
</style>
