<template>
  <ProfilePage />
</template>

<script>
import ProfilePage from "@/components/layout/user/ProfilePage";
export default {
  name: "Profile",
  components: { ProfilePage }
};
</script>

<style scoped></style>
